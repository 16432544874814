h1,
.h1 {

  @include media-breakpoint-down(md) {
    font-size: $mobile-h1-font-size;
  }

  @include media-breakpoint-down(sm) {
    font-size: calculateRem($font-size-base, $font-size-base-px, 32);
  }
}

h2,
.h2 {
  
  
  @include media-breakpoint-down(md) {
    font-size: $mobile-h2-font-size;
  }
  
  @include media-breakpoint-down(sm) {
    font-size: calculateRem($font-size-base, $font-size-base-px, 32);
  }
}

h3,
.h3 {
  @include media-breakpoint-down(md) {
    font-size: $mobile-h3-font-size;
  }
}

h4,
.h4 {
  @include media-breakpoint-down(md) {
    font-size: $mobile-h4-font-size;
  }
}

h5,
.h5 {
  @include media-breakpoint-down(md) {
    font-size: $mobile-h5-font-size;
  }
}

h6,
.h6 {
  @include media-breakpoint-down(md) {
    font-size: $mobile-h6-font-size;
  }
}

.lead {
  font-family: $lead-font-family;

  @include media-breakpoint-down(md) {
    font-size: $mobile-font-size-lg;
  }
}

blockquote {
  padding: $grid-gutter-width / 2 $grid-gutter-width;
  border-left: 4px solid $blue-light;
  background-color: $gray-100;
  font-style: italic;

  > p {
    &:last-child {
      margin: 0;
    }
  }
}