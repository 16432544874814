.dd-language {
  width: 100%;

  &.show > .btn-secondary.dropdown-toggle {
    background-color: transparent;
    border: transparent;

    &:focus {
      box-shadow: none;
    }
  }

  .toggle {
    background-color: unset;
    border: 0px;
    padding-left: 0;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      border: transparent;
    }

    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  .dropdown-menu,
  .dropdown-menu.show {
    background-color: $blue-dark;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .dropdown-menu.show {
  }

  .dropdown-item {
    padding-left:  0;
  }
  & .show {
    background-color: transparent;
    border: transparent;

    .dropdown-item {
      color: white;
      &:hover {
        background-color: transparent;
        border: transparent;
      }
    }
  }
}
