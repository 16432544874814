body {
  // overflow-y: scroll;
  padding-top: 60px;

  @include media-breakpoint-up(md) {
    padding-top: $navbar-height;
  }

  @include media-breakpoint-down(sm) {
    font-size: $mobile-font-size-base;
  }
}

main {
  min-height: 100vh;
}

section {
  padding: $section-padding-y 0;
  overflow-y: hidden;

  @include media-breakpoint-down(sm) {
    padding: ($section-padding-y / 2) 0;
  }

  &.section-branches-relevant {
    padding: 0;
  }

  &.section-detail {
    padding-bottom: $section-padding-y / 4;
    @include media-breakpoint-down(xs) {
      padding-bottom: 0;
    }

    &-service {
      padding-bottom: 0;
    }
  }

  &.section-detail-footer {
    padding-top: $section-padding-y / 4;

    @include media-breakpoint-down(xs) {
      padding-top: 0;
    }
  }

  &.section-admin {
    padding: 0;
    > .container-fluid {
      padding: 0;
      > .row {
        margin: 0;
        > div {
          padding: 0;
        }
      }
    }
  }
}

figure {
  margin: 0;
}

.mb-section {
  @include media-breakpoint-down(sm) {
    margin-bottom: ($section-padding-y / 2) !important;
  }
}
