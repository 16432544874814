.section-company-location {
  &-list {
    background-color: $blue-darker;
    color: $white;
    padding: 3.625rem 0;
  }
}

.company-location {
  &-list {
    text-align: center;

    .slick {
      &-slide {
        > div {
          height: 40vh;
          min-height: 250px;

          @include media-breakpoint-between(xs, sm) {
            height: 30vh;
          }
          @include media-breakpoint-up(md) {
            height: 35vw;
          }
          @include media-breakpoint-up(xl) {
            height: 25vw;
          }
        }
      }
      &-prev,
      &-next {
        &:hover {
          &:before {
            color: white;
          }
          opacity: 1;
        }
        @include media-breakpoint-up(lg) {
          opacity: 0.4;
        }
      }

      &-prev {
        top: 50%;
        @include media-breakpoint-up(sm) {
          top: 35%;
          left: 35px;
        }
        @include media-breakpoint-up(md) {
          left: -76px;
        }
      }

      &-next {
        top: 50%;

        @include media-breakpoint-up(sm) {
          top: 35%;
          right: 35px;
        }
        @include media-breakpoint-up(md) {
          right: -76px;
        }
      }
    }

    &-item {
      display: inline-block;
      font-size: 400%;
      opacity: 0.4;
      text-align: center;
      padding: $spacer * 3.5;
      transition: 0.45s ease;

      @include media-breakpoint-down(md) {
        font-size: 300%;
        padding: $spacer * 3 0;
      }

      @include media-breakpoint-between(xxs, md) {
        opacity: 1;
      }

      &:hover {
        opacity: 1;
      }

      &:hover &-text-description {
        opacity: 1;
      }
    }

    &-item-text {
      font-size: 0.325em;
      padding-bottom: 2vh;
    }

    &-item-text-description {
      font-size: 0.2em;
      opacity: 0;

      @include media-breakpoint-down(xs);
      @include media-breakpoint-down(xxs);
      @include media-breakpoint-down(md) {
        opacity: 1;
      }
    }
    &-item-text-phone {
      padding-top: 3vh;
    }
  }
}
