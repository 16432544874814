.section-employer-testimonal-list {
  .slick {
    &-next,
    &-prev {
      &:before {
        color: black;
      }
    }
    &-slide {
      > div {
        height: auto;
      }
    }
  }
  h4,
  .h4 {
    margin-bottom: 0.8rem !important;
  }
  .employer-testimonal-list-item {
    &-data {
      padding-left: 5rem;
    }
    &-content {
      p {
        font-weight: 600;
      }
    }
  }
}
