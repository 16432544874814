
@font-face {
  font-family: 'fontello';
  src: url('./fontello.eot?49654864');
  src: url('./fontello.eot?49654864#iefix') format('embedded-opentype'),
  url('./fontello.woff2?49654864') format('woff2'),
  url('./fontello.woff?49654864') format('woff'),
  url('./fontello.ttf?49654864') format('truetype'),
  url('./fontello.svg?49654864#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

$fontello: (
  'globe': ('content': '\e800'), /* '' */
  'mountain': ('content': '\e801'), /* '' */
  'gear': ('content': '\e802'), /* '' */
  'user': ('content': '\e803'), /* '' */
  'flip': ('content': '\e804'), /* '' */
  'gearUser': ('content': '\e805'), /* '' */
  'heart': ('content': '\e806'), /* '' */
  'jobs': ('content': '\e807'), /* '' */
  'phone': ('content': '\e808'), /* '' */
  'mail': ('content': '\e809'), /* '' */
  'fb': ('content': '\e80a'), /* '' */
  'in': ('content': '\e80b'), /* '' */
  'insta': ('content': '\e80c'),
  'facebook': ('content': '\e80a'),
  'linkedin': ('content': '\e80b'),
  'instagram': ('content': '\e80c'),
  'arrow-left': ('content': '\e80d'),
  'arrow-right': ('content': '\e80e'),
  'arrow-down': ('content': '\e80f'),
  'arrow-up': ('content': '\e810'),
  'flexibel': ('content': '\e811'),
  'inspiring': ('content': '\e812'),
  'partnership': ('content': '\e813'),
  'authentic': ('content': '\e814'),
  'sustainable': ('content': '\e815'),
  'braunschweig': ('content': '\e816'),
  'hannover': ('content': '\e817'),
  'berlin': ('content': '\e818'),
  'pristina': ('content': '\e819'),
  'tirana': ('content': '\e81a'),
  'star_empty': ('content': '\e81b'),
  'twitter': ('content': '\e81c'),
  'youtube': ('content': '\f167'),
  'xing': ('content': '\f168'),
  'circle_thin': ('content': '\f1db'),
);

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

@each $key, $item in $fontello {
  .icon-#{$key}:before {
    content: map-get($item, 'content');
  }
}
