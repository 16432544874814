.page-content {
  &-text,
  &-text-lead,
  &-text-with-image {
    &:last-child {
      margin-bottom: $section-padding-y;

      @include media-breakpoint-down(sm) {
        margin-bottom: ($section-padding-y / 2);
      }
    }
    & + div,
    & + section {
      margin-top: $grid-gutter-width;

      @include media-breakpoint-down(sm) {
        margin-top: $grid-gutter-width / 2;
      }
    }

    & + div:not(.page-content-text-with-image) {
      @include media-breakpoint-down(sm) {
        margin-top: 0;
      }
    }

    .img-wrap {
      margin-bottom: $grid-gutter-width;

      @include media-breakpoint-down(sm) {
        margin-bottom: $grid-gutter-width / 2;
      }

      &-bottom {
        margin-top: $grid-gutter-width;

        @include media-breakpoint-down(sm) {
          margin-top: $grid-gutter-width / 2;
        }
      }
    }

    ul {
      padding-left: 22px;

      > li {
      }
    }

    h3,
    h4,
    h5,
    h6,
    .h3,
    .h4,
    .h5,
    .h6 {
      margin-bottom: 0;

      & + ul {
        margin-top: $grid-gutter-width / 2;
      }
    }
  }

  &-text {
    &-lead {
      .lead {
        font-weight: $font-weight-bold;
      }
    }

    & + .jumbotron {
      padding: $section-padding-y 0;

      @include media-breakpoint-down(sm) {
        padding: ($section-padding-y / 2) 0;
      }
    }
  }

  &-text,
  &-text-lead,
  &-text-with-image {
    @include media-breakpoint-down(sm) {
      padding-right: $grid-gutter-width / 2;
      padding-left: $grid-gutter-width / 2;
    }
    @include media-breakpoint-down(xs) {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.img-wrap {
  figcaption {
    display: block;
    font-size: $font-size-sm;
    padding: $grid-gutter-width / 2 0;
    opacity: 0.5;

    @include media-breakpoint-up(md) {
      max-width: 60%;
    }
  }
}

.page-content-text {
  & + .section-teaser,
  & + .jumbotron-small.jumbotron {
    margin-top: $section-padding-y;

    @include media-breakpoint-down(sm) {
      margin-top: ($section-padding-y / 2);
    }
  }
}

.section-teaser {
  & + .page-content-text {
    margin-top: $section-padding-y;
    @include media-breakpoint-down(sm) {
      margin-top: ($section-padding-y / 2);
    }
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
