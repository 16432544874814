@import './Vendors/bootstrap.scss';
@import '../../../node_modules/bootstrap/scss/bootstrap';

@import '../../../node_modules/slick-carousel/slick/slick.scss';
@import '../../../node_modules/slick-carousel/slick/slick-theme.scss';

@import './Theme/index.scss';

.form-group {
  .was-validated :invalid ~ .invalid-feedback, 
  .was-validated :invalid ~ .invalid-tooltip, 
  .is-invalid ~ .invalid-feedback, 
  .is-invalid ~ .invalid-tooltip {
    display: inline-block;
    width: auto;
  }
}
