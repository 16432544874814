.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: $zindex-tooltip;

  .container, .row {
    height: 100%;
  }

  .row {
    align-items: center;
    text-align: center;
  }

  img {
    display: block;
    width: 40%;
    margin: auto;
  }

  &-indicator {
    position: fixed;
    right: $container-padding-y / 4;
    bottom: $container-padding-y / 4;
    // background-color: rgba(255,255,255, 0.5);
    height: $spacer * 2 + ($grid-gutter-width / 2);
    padding: $grid-gutter-width / 4 $grid-gutter-width / 2;
    border-radius: $border-radius;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transform: $transition-slow;

    color: $blue-light;

    &.active {
      opacity: 1;
      z-index: $zindex-tooltip;
      visibility: visible;
    }

    .lead {
      display: inline-block;
      height: $spacer * 2;
      position: relative;
      top: -4px;
      padding-right: $grid-gutter-width / 2;
    }

    .spinner-grow {
      width: $spacer * 2;
      height: $spacer * 2;
      background-color: $blue-light;
    }
  }
}
