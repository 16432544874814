@include media-breakpoint-up(sm) {
  .competence {
    &-section {
      padding-top: 10vw;
    }
    &-container {
      display: flex;
      justify-content: center;
      position: absolute;
      padding: 10vw;
      padding-left: 20vw;
      padding-right: 22vw;
    }

    &-title {
      height: 17vw;
      width: 17vw;
      line-height: 17vw;
      margin-top: 1vw;
      font-size: 1.5vw;
      font-family: "AmpleSoft";
    }

    &-list {
      position: absolute;
      width: 11vw;
    }

    &-item {
      &-consulting {
        background-color: $blue-darker;
        &:hover {
          transition: all 0.5s;
          background-color: $green;
        }
      }
      &-digital {
        background-color: $blue;
      }

      &-padding-top-2 {
        padding-top: 2vw !important;
      }
      &-padding-top-3 {
        padding-top: 3vw !important;
      }
    }
  }

  .selector {
    position: relative;
    height: 20vw;
    margin-top: 20vw;
    width: 50vw;
    font-weight: 300;
    font-family: "AmpleSoft";
    margin-left: auto;
    margin-right: auto;
  }

  .selector ul {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    top: -20px;
    right: -20px;
    bottom: -20px;
    left: -20px;
  }

  .selector li {
    width: 50vw;
    //height: 40vw;
    -webkit-transform: rotate(-360deg);
    transition: all 0.8s ease-in-out;
    position: absolute;
  }

  .selector li::after {
    position: absolute;
  }

  .selector li label {
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    right: 0;
    position: absolute;
    padding-top: 4vw;
    color: white;
    font-size: 1vw;
  }

  .selector li label:hover {
    transition: all 0.5s;
    background-color: $green;
  }

  .selector.open li label {
    width: 80px;
    height: 80px;
    line-height: 80px;
    margin-left: -40px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    font-size: 14px;
  }
}
@include media-breakpoint-between(sm, md) {
  .competence {
    &-container {
      padding-right: 24vw;
    }
  }
}
@include media-breakpoint-down(sm) {
  .competence {
    &-list {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
    }
    &-item {
      &-consulting {
        font-family: "AmpleSoft";
        border: 1px solid;
        border-radius: 50%;
        width: 165px;
        height: 165px;
        color: white;
        background: $blue-darker;
        text-align: center;
        padding-top: 40%;
        &-title {
          height: 50px;
        }
      }
      &-digital {
        font-family: "AmpleSoft";
        border: 1px solid;
        border-radius: 50%;
        width: 165px;
        height: 165px;
        color: white;
        background: $blue;
        text-align: center;
        padding-top: 40%;
        &-title {
          height: 50px;
        }
      }
      &-seemore {
        border: 1px solid;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1vw;
        margin-bottom: auto;
      }
    }
  }
}
@include media-breakpoint-down(xs) {
  .competence {
    &-item {
      &-padding-top-2 {
        padding-top: 20% !important;
      }
      &-padding-top-3 {
        padding-top: 30% !important;
      }
    }
  }
}
