.blog {
  @include media-breakpoint-up(sm) {
    &-container {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
    }

    &-0,
    &-6 {
      grid-column: span 6;
      grid-row: span 2;
      height: 45vw;
      position: relative;

      .blog-item-img {
        height: 45vw;
        position: absolute;
        width: 100%;
      }

      .blog-item-text {
        &-content {
          position: absolute;
          right: $container-padding-y;
          left: $container-padding-y / 2;
          bottom: calculateRem($font-size-base, $font-size-base-px, 75);
          color: $white;
          z-index: 60;
          line-height: 1;
          font-family: 'AmpleSoft';
        }

        &-date {
          font-size: calculateRem($font-size-base, $font-size-base-px, 15);
          padding-bottom: 2rem;
        }
        &-title {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-size: calculateRem($font-size-base, $font-size-base-px, 35);
          font-weight: 600;
        }
      }
    }

    &-1,
    &-7 {
      grid-column: span 6;
      height: 25vw;
      position: relative;

      .blog-item-img {
        height: 25vw;
        position: absolute;
        background: white;
        width: 100%;
        > img,
        > figure {
          width: 50%;
        }
      }

      .blog-item-text {
        &-content {
          position: absolute;
          bottom: 0;
          color: $blue-darker;
          z-index: 60;
          line-height: 1.2;
          font-family: 'AmpleSoft';
          width: 50%;
          right: 0;
          padding: 0 3rem 6rem;
        }
        &-date {
          font-size: calculateRem($font-size-base, $font-size-base-px, 15);
          padding-bottom: 2rem;
        }
        &-title {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-size: calculateRem($font-size-base, $font-size-base-px, 30);
          font-weight: 600;
        }
      }
    }

    &-2,
    &-5 {
      grid-column: span 6;
      height: 20vw;
      position: relative;
      background-color: $blue-darker;

      .blog-item-text {
        &-content {
          position: absolute;
          bottom: calculateRem($font-size-base, $font-size-base-px, 100);
          color: $white;
          z-index: 60;
          line-height: 1.2;
          font-family: 'AmpleSoft';
          width: 55%;
          padding-left: calculateRem($font-size-base, $font-size-base-px, 70);
        }
        &-date {
          font-size: calculateRem($font-size-base, $font-size-base-px, 15);
          padding-bottom: 2rem;
        }
        &-title {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-size: calculateRem($font-size-base, $font-size-base-px, 30);
          font-weight: 600;
        }
      }
    }

    &-3,
    &-4 {
      grid-column: span 6;
      height: 25vw;
      position: relative;

      .blog-item-img {
        height: 25vw;
        position: absolute;
        background: white;
        width: 100%;

        > img,
        > figure {
          width: 50%;
          right: 0;
          position: absolute;
        }
      }

      .blog-item-text {
        &-content {
          position: absolute;
          bottom: 0;
          color: $blue-darker;
          z-index: 60;
          line-height: 1.2;
          font-family: 'AmpleSoft';
          width: 50%;
          left: 0;
          padding: 0 3rem 6rem;
        }
        &-date {
          font-size: calculateRem($font-size-base, $font-size-base-px, 15);
          padding-bottom: 2rem;
        }
        &-title {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-size: calculateRem($font-size-base, $font-size-base-px, 30);
          font-weight: 600;
        }
      }
    }

    &-8 {
      grid-column: span 12;
      height: 45vw;
      position: relative;

      .blog-item-img {
        height: 45vw;
        position: absolute;
        width: 100%;
      }

      .blog-item-text {
        &-content {
          position: absolute;
          right: $container-padding-y;
          left: $container-padding-y / 2;
          bottom: calculateRem($font-size-base, $font-size-base-px, 75);
          color: $white;
          z-index: 60;
          line-height: 1;
          font-family: 'AmpleSoft';
        }

        &-date {
          font-size: calculateRem($font-size-base, $font-size-base-px, 15);
          padding-bottom: 2rem;
        }
        &-title {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-size: calculateRem($font-size-base, $font-size-base-px, 55);
          font-weight: 600;
          width: 40%;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &-container {
      display: grid;
      padding: 0;
    }

    &-0,
    &-6,
    &-1,
    &-7,
    &-2,
    &-5,
    &-3,
    &-4,
    &-8 {
      position: relative;

      .blog-item-img {
        height: 60vh;
        margin: 0;
      }

      .blog-item-text {
        &-content {
          width: 100%;
          position: absolute;
          right: $container-padding-y;
          left: 0;
          bottom: 0;
          // bottom: calculateRem($font-size-base, $font-size-base-px, 75);
          color: $white;
          z-index: 60;
          line-height: 1;
          font-family: 'AmpleSoft';
          padding: ($grid-gutter-width / 2);
        }

        &-date {
          padding-bottom: 1rem;
        }
        &-title {
          display: -webkit-box;
          -webkit-line-clamp: 6;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-size: calculateRem($font-size-base, $font-size-base-px, 29);
          font-weight: 600;
        }
      }
    }

    &-1,
    &-7,
    &-2,
    &-5,
    &-3,
    &-4 {
      .blog-item-text {
        &-title {
          -webkit-line-clamp: 4;
          font-size: calculateRem($font-size-base, $font-size-base-px, 25);
        }
      }
    }

    &-2,
    &-5 {
      height: 60vh;
      position: relative;
      background-color: $blue-darker;
    }
  }

  &-author {
    &-image {
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 100%;

      > .img-wrap {
        position: absolute;
      }
    }
  }
}

.blog-item {
  &-row {
    position: relative;
  }

  &-img {
    background-color: $blue-darker;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
      margin: $section-padding-y / 2 0;
    }

    > img,
    > figure,
    > figure > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      min-height: 100%;
    }
  }

  &-tag {
    margin-top: $spacer * 3;
    text-transform: uppercase;
    font-size: calculateRem($font-size-base, $font-size-base-px, 12);

    .span-trim {
      padding: 0 0.3rem;
    }
  }

  &-content-section {
    padding: $section-padding-y/2 0;

    @include media-breakpoint-down(sm) {
      padding-top: 0;
    }
  }
}

.blog-detail {
  &-link {
    color: $blue-light;
  }
  .icon-arrow-left {
    font-size: $spacer;
  }
  .nav-social {
    // margin-bottom: $spacer * 3;
  }
  .nav-link {
    color: black;
    padding-left: 0;
    font-size: $font-size-base * 2;
  }

  .blog-item-img {
    // height: 30vw;
    // max-height: 30vw;

    @include media-breakpoint-up(md) {
      height: 30vw;
      max-height: 30vw;
    }

    > figure > img {
      height: auto;
    }
  }
}

.blog-next {
  &-container {
    background-color: $blue-darker;
    padding-bottom: $headings-margin-bottom;
    padding-top: $headings-margin-bottom;

    .icon-arrow-right {
      font-size: $spacer;
    }
  }
  &-title {
    margin-bottom: $spacer * 0.75;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.blog-preview {
  &-section {
    .blog-button-container {
      margin: 6rem 0;
    }
  }
}
